<div class="container">

    <div class="jumbotron">
		              <h1>Application and registration</h1>
            <p>This is on-line application system for Master of Science, ISAE-SUPAERO engineer, Advanced Master graduate programs and associated scholarships.<br>
			<!-- <A class='form_link5' HREF='https://candidatures.isae.fr/images/MScApplicationProcedure_v2019.pdf' target="_blank">Download the application procedure document</A>
				<p class="text-center"> --><BR>
				<a class='btn btn-primary' routerLink='./form/candform' role='button'>Apply &raquo;  </a>		</p>
				<!-- <p style="color:red;">This on-line application system is compatible with Mozilla Firefox and Google Chrome</p> -->
				<!-- <p style="color:red;"><b>Applications are closed for 2022-2023 intakes.<br>Applications for September 2023 intake will open on October 2021, 4th. <br>Admitted students can always register and upload registration documents.</b></p> -->
				<!--<p style="color:red;"><b>The applications for the Double degree TUM/ISAE-SUPAERO are closed for 2022.</b></p> --> 
				<!--<p style="color:red;"><b>Applications for 2023 intake are open! Any question ? Please contact info-masters@isae-supaero.fr</b></p> -->
        
        <!--<p style="color:red;"><b>Please note applications are closed for the Double Degree Master in Aerospace Systems Engineering between TUM/ISAE-SUPAERO for Sept.2024 intake</b></p> -->
        <!--<p style="color:red;"><b>Please note that applications for the Master in Aerospace and Double Degree  are now closed for Sept.2024 intake</b></p> 
        <p style="color:green;"><b>Applications for Advanced Masters are still open, until August 19th.</b></p> <p style="color:black;"><b> (Applications for the SPAPS Advanced Master are closed for 2024 intake.)</b></p> -->
        <p style="color:red;"><b>Applications are closed for Sept. 2024 intakes.</b></p><br>
        <p style="color:black;"><b>- For more info about applications : please contact info-programmes@isae-supaero.fr </b></p> 

    <!--<p style="color:red;"><b>APPLICATIONS FOR 2023-2024 WILL START NEXT OCTOBER. PLEASE CONTACT info-masters@isae-supaero.fr.</b></p> -->
  </div>

  <div class="row">
    <div class="col-sm-4">
      <div class="pricing-table-container">
        <div class="panel panel-success">
          <div class="panel-heading text-center" style="min-height: 240px;">
            <h2><span data-toggle="tooltip" data-placement="bottom" title="Master of Science">Masters of Science</span>
            </h2>
            <span data-toggle="tooltip" data-placement="bottom">
								<div class="row">
								<div class="col-sm-12">- Master in Aerospace Engineering (ISAE-SUPAERO)</div>
								</div>
								<div class="row">
									<div class="col-sm-12">- Double degree TUM/ISAE-SUPAERO</div>
								</div>
							</span>
          </div>
        </div>
      </div>
    </div><!--/.col-xs-6.col-lg-4-->

    <div class="col-sm-4">
      <div class="pricing-table-container">
        <div class="panel panel-warning">
          <div class="panel-heading text-center" style="min-height: 240px;">
            <h2><span data-toggle="tooltip" data-placement="bottom" title="Advanced Masters">Advanced Masters</span>
            </h2>
            <span data-toggle="tooltip" data-placement="bottom">
							<div class="row">
								<div class="col-sm-12">AES, AIBT, AMPAS, AMS, APM, ASAA</div>
							</div>
							<div class="row">
								<div class="col-sm-12">EMS, HADA, MGPIE, SEN, SPA</div>
							</div>
							<div class="row">
								<div class="col-sm-12">SPAPS, TAS-Aero, TAS-Aero(FTE)</div>
							</div>
							<div class="row">
								<div class="col-sm-12">TAS-Astro, TAS-Astro-Seeds</div>
							</div>
						</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="pricing-table-container">
        <div class="panel panel-danger">
          <div class="panel-heading text-center" style="min-height: 240px;">
            <h2><span data-toggle="tooltip" data-placement="bottom" title="PG DIPLOMA">PG Diploma&nbsp; </span></h2>
            <span data-toggle="tooltip" data-placement="bottom">
							<div class="row">
								<div class="col-sm-12">AES, AMS-E&M, HADA</div>
							</div>
							<div class="row">
								<div class="col-sm-12">SEN, SPAPS, TAS Aero</div>
							</div>
							<div class="row">
								<div class="col-sm-12">TAS Astro, MGPIE, TAS Aero FTE</div>
							</div>
						</span>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="pricing-table-container">
        <div class="panel panel-default">
          <div class="panel-heading text-center" style="min-height: 65px;">
            <a class='form_link5' HREF='https://candidatures.isae.fr/images/MScApplicationProcedure_v2019.pdf'
               target="_blank"><span class="glyphicon glyphicon-file" aria-hidden="true"></span><b> Download</b><BR>the
              application procedure document</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">

  <div class="row">
    <div class="col-sm-4">
      <div class="pricing-table-container">
        <!--div class="panel-body "-->
        <ul class="plan-ul">

          <li>
            <strong>Requirements </strong>
            <br/>
            Bachelor degree in engineering or sciences<a href="#"></a> <a
            href='https://www.isae-supaero.fr/en/academics/MSc/isae-supaero-master-s-degree-msc/'>(more details)</a>
          </li>
          <li><strong>Teaching Language</strong> <br/>English</li>
          <li><a href="https://www.isae-supaero.fr/en/academics/MSc/admission/admissions/" target='_blank'>
            <span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span> Admission schedule &
            documents</a><BR>
            <a href="https://www.isae-supaero.fr/en/academics/MSc/financing-144/financing/" target='_blank'>
              <span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span> Scholarship information</a></li>
        </ul>


      </div>
    </div>

    <div class="col-sm-4">
      <div class="pricing-table-container">
        <ul class="plan-ul">
          <li>
            <strong>Requirements </strong>
            <br/>
            Master degree or Bachelor degree + 3 years of professional experience <a
            href='https://www.isae-supaero.fr/en/academics/advanced-masters/presentation/'>(more details)</a>
          </li>
          <li><strong>Teaching Language</strong> <br/>French or English, depending on the program</li>
          <li><a href="https://www.isae-supaero.fr/en/academics/advanced-masters/admissions/" target='_blank'>
            <span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span> Admission schedule &
            documents</a><br>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="pricing-table-container">
        <ul class="plan-ul">
          <li>
            <strong>Requirements </strong>
            <br/>
            Master degree or Bachelor degree + 3 years of professional experience <a
            href='https://www.isae-supaero.fr/en/academics/postgraduate-diplomas/postgraduate-diplomas-671/'>(more
            details)</a>
          </li>
          <li><strong>Teaching Language</strong> <br/>French or English, depending on the program</li>
          <li><a href="https://www.isae-supaero.fr/en/academics/advanced-masters/admissions/" target='_blank'>
            <span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span> Admission schedule &
            documents</a><br>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
